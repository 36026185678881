<template>
  <div ref="message">
    <div
      v-if="errorMessage"
      class="bg-red-600 text-white px-3 py-2 rounded-sm relative text-sm my-6"
      role="alert">
      {{ errorMessage }}
    </div>
    <div
      v-if="errors && Object.keys(errors).length !== 0"
      class="bg-red-600 text-white px-3 py-2 rounded-sm relative text-sm my-6"
      role="alert">
      <div
        v-for="(error,index) in errors"
        :key="index">
        <div
          v-for="(value,vIndex) in error"
          :key="vIndex">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default () {
        return {}
      }
    },

    errorMessage: {
      type: String,
      default: null
    }
  },

  watch: {
    errors (value) {
      if (Object.keys(value).length) {
        this.$nextTick(() => {
          this.$refs.message.scrollIntoView()
        })
      }
    }
  }
}
</script>
