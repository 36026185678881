<template>
  <div>
    <label
      class="block text-gray-800 text-sm font-bold mb-2"
      v-if="label">
      {{ label }}
    </label>
    <input
      v-model="model"
      ref="input"
      class="block appearance-none bg-white rounded leading-snug focus:outline-none focus:shadow-outline text-black border border-gray-400"
      :id="id"
      :class="{ 'text-center': centered }"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      :inputmode="inputmode"
      :pattern="pattern"
      :maxlength="maxlength"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      @focus="$emit('focus')">
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    value: {
      type: [String, Number],
      default: null
    },

    centered: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: "text"
    },

    placeholder: {
      type: String,
      default: null
    },

    inputmode: {
      type: String,
      default: null
    },

    pattern: {
      type: String,
      default: null
    },

    autocomplete: {
      type: String,
      default: null
    },

    maxlength: {
      type: String,
      default: null
    },
  },

  mounted () {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
