<template>
  <div class="radio-button inline-flex flex-wrap sm:flex-no-wrap">
    <label
      v-for="(option,index) in options"
      :key="index"
      :class="{ 'selected': option.value === value }"
      class="w-full sm:w-1/2">
      <input
        type="radio"
        class="clip"
        :name="'radio' + uid"
        :value="option.value"
        @input="$emit('input', option.value)">
      {{ option.title }}</label>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    value: {
      type: [String, Object, Boolean],
      default: false
    }
  },

  computed: {
    uid() {
      return "rb-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>

<style lang="scss">
  .radio-button {
    @apply justify-center;

    label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @apply px-16;
      @apply py-4;
      @apply rounded-sm;
      @apply rounded;
      @apply border-2;
      @apply cursor-pointer;
      @apply select-none;
      @apply my-2;
      @apply bg-purple-200;
      background-color: var(--list-item-background-color);
      @apply border-purple-300;
      border-color: var(--list-item-border-color);
      color: var(--list-item-text-color);

      @screen sm {
        @apply m-2;
        @apply text-lg;
      }

      &:hover {
        @screen md {
          background-color: var(--list-item-background-color-active);
          border-color: var(--list-item-border-color-hover);
          color: var(--list-item-text-color-active);
        }
      }

      &:active {
        position: relative;
        top: 1px;
      }

      &.selected {
        background-color: var(--list-item-background-color-active);
        border-color: var(--list-item-border-color-hover);
        color: var(--list-item-text-color-active);
      }

      &:focus, &:focus-within {
        @apply outline-none;
        @apply shadow-outline;
      }
    }
  }
</style>

