<template>
  <div class="radio-list">
    <label
      v-for="option in options"
      :key="option.key"
      :class="{ 'selected': option[optionValue] === value }">
      <input
        type="radio"
        class="clip"
        :name="'radio' + uid"
        :value="option[optionKey]"
        @input="$emit('input', option[optionKey])">
      {{ option[optionValue] }}</label>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    optionValue: {
      type: String,
      default: "title"
    },

    optionKey: {
      type: String,
      default: "id"
    },

    value: {
      type: [String, Boolean],
      default: false
    }
  },

  computed: {
    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>

<style lang="scss">
  .radio-list {
    @apply inline-flex;
    @apply flex-col;
    @apply items-start;
    @apply w-full;

    label {
      display: block;
      @apply px-4;
      @apply py-2;
      @apply rounded-sm;
      @apply rounded;
      @apply border-2;
      @apply cursor-pointer;
      @apply select-none;
      @apply mb-2;
      @apply w-full;
      background-color: var(--list-item-background-color);
      border-color: var(--list-item-border-color);
      color: var(--list-item-text-color);

      &:hover {
        @screen md {
          background-color: var(--list-item-background-color-active);
          border-color: var(--list-item-border-color-hover);
          color: var(--list-item-text-color-active);
        }
      }

      &:active {
        position: relative;
        top: 1px;
      }

      &.selected {
        background-color: var(--list-item-background-color-active);
        border-color: var(--list-item-border-color-hover);
        color: var(--list-item-text-color-active);
      }

      &:focus, &:focus-within {
        @apply outline-none;
        @apply shadow-outline;
      }
    }
  }
</style>
