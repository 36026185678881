<template>
  <div class="checkbox">
    <input
      v-model="model"
      class="clip"
      type="checkbox"
      :id="`checkbox-${uid}`"
      :disabled="disabled">
    <label
      :for="`checkbox-${uid}`"
      class="flex items-center justify-start select-none cursor-pointer">
      <div class="mr-3">
        <div
          class="flex items-center justify-center rounded w-8 h-8 border bg-white checkbox__icon"
          :class="{ 'checkbox__icon--checked': model, 'shadow-inner': !model, 'checkbox__icon--disabled' : disabled }">
          <svg
            v-if="model"
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 1L4.6 10L1 5.30435"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div
        :class="labelClass"
        class="text-gray-800">
        <slot>
          {{ label }}
        </slot>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String, Object],
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: null
    },

    labelClass: {
      type: [String, Object],
      default: null
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>

<style lang="scss">
  .checkbox {
    input:checked+label {
      .checkbox__icon {
        opacity: 1;
      }
    }

    &__icon {
      @apply border-gray-800;
      border-color: var(--checkbox-border-color);

      &--checked {
        @apply border-gray-800;
        border-color: var(--checkbox-border-color-active);
        @apply bg-gray-800;
        background-color: var(--checkbox-background-color-active);
      }

      &--disabled {
        background-color: var(--checkbox-background-color-disabled);
      }
    }

    &:focus, &:focus-within {
      @apply outline-none;
      @apply shadow-outline;
      @apply rounded;
    }
  }
</style>
