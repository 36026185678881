<template>
  <div>
    <label
      class="block text-gray-800 text-sm font-bold mb-2"
      :for="uid">
      {{ label }}
    </label>
    <textarea
      v-model="model"
      :class="[heightClass, 'appearance-none block w-full rounded bg-white border border-gray-400 px-4 py-3 pr-8 leading-tight focus:outline-none focus:shadow-outline']"
      :id="uid"
      type="text"
      :placeholder="placeholder"
      :autofocus="autofocus" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Select an option"
    },

    value: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    heightClass: {
      type: String,
      default: "h-32"
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "ta-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>
