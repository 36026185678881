<template>
  <h1
    class="text-2xl leading-tight text-gray-800 headline"
    :class="{ 'sm:text-5xl': !small, 'font-bold': !light, 'font-light': light }">
    <slot />
  </h1>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },

    light: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .headline {
    font-family: var(--headline-font);
    color: var(--headline-color);
  }
</style>
