<template>
  <div>
    <label
      class="block text-gray-800 text-sm font-bold mb-2"
      v-if="label">
      {{ label }}
    </label>
    <div class="inline-block relative w-full border border-gray-400 rounded">
      <select
        :id="id"
        v-model="model"
        :disabled="disabled"
        :name="name"
        class="block appearance-none w-full bg-white px-4 py-3 pr-8 rounded leading-snug focus:outline-none focus:shadow-outline placeholder-gray-500"
        :placeholder="placeholder"
        :class="{ 'text-gray-400': disabled, 'text-black': !disabled }"
        :required="required">
        <option
          :value="emptyValue"
          v-if="placeholder"
          :disabled="!allowEmpty">
          {{ placeholder }}
        </option>
        <option
          :value="null"
          v-if="allOption">
          {{ allOption }}
        </option>
        <option
          v-for="(option,index) in options"
          :value="option[optionKey]"
          :key="index">
          {{ option[optionValue] }}
        </option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black">
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    optionValue: {
      type: String,
      default: "name"
    },

    optionKey: {
      type: String,
      default: "id"
    },

    label: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: ""
    },

    name: {
      type: String,
      default: null
    },

    id: {
      type: String,
      default: null
    },

    allOption: {
      type: String,
      default: null
    },

    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    allowEmpty: {
      type: Boolean,
      default: false
    },

    emptyValue: {
      type: [String, Number],
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
