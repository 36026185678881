<template>
  <button
    :class="classObject"
    :disabled="loading || disabled"
    class="next-button rounded-full focus:outline-none focus:shadow-outline h-16 w-16 inline-flex items-center justify-center">
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1305 0.979341C14.3495 0.198293 13.0831 0.198293 12.3021 0.979341C11.521 1.76039 11.521 3.02672 12.3021 3.80777L16.1887 7.69434L2.11328 7.69434C1.00871 7.69434 0.113281 8.58977 0.113281 9.69434C0.113281 10.7989 1.00871 11.6943 2.11328 11.6943L15.9279 11.6943L12.3021 15.3202C11.521 16.1012 11.521 17.3676 12.3021 18.1486C13.0831 18.9297 14.3495 18.9297 15.1305 18.1486L21.8104 11.4688C22.45 11.1351 22.8868 10.4657 22.8868 9.69434C22.8868 9.67233 22.8864 9.65041 22.8857 9.62857C22.9028 9.09542 22.7079 8.55672 22.3009 8.14976L15.1305 0.979341Z"
        fill="currentColor" />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classObject () {
      return {
        "loading": this.loading,
        "disabled": this.disabled
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .next-button {
    @apply bg-gray-800;
    background-color: var(--next-button-background-color);
    color: var(--next-button-text-color);

    &.disabled {
      @apply bg-gray-400;
      background-color: var(--next-button-background-color-disabled);
    }
  }
</style>
