<template>
  <div class="checkbox-list">
    <div
      v-for="(option,index) in options"
      :key="index">
      <label
        :class="{ 'selected': option[optionKey] === value, 'items-start' : alignment === 'top', 'items-center': alignment === 'center' }"
        class="flex justify-start select-none cursor-pointer mt-4">
        <input
          v-model="model"
          class="clip"
          type="checkbox"
          :value="option[optionKey]">
        <div class="mr-3">
          <div
            class="flex items-center justify-center rounded w-8 h-8 border shadow-inner checkbox-list__icon"
            :class="{ 'checkbox-list__icon--checked': model.indexOf(option[optionKey]) > -1, 'shadow-inner': model.indexOf(option[optionKey]) === -1 }">
            <svg
              v-if="model.indexOf(option[optionKey]) > -1"
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 1L4.6 10L1 5.30435"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="pr-3">
          <slot :item="option">
            {{ option.title }}
          </slot>
        </div>
      </label>
      <slot
        name="footer"
        :item="option" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    optionValue: {
      type: String,
      default: "name"
    },

    optionKey: {
      type: String,
      default: "id"
    },

    value: {
      type: Array,
      default () {
        return []
      }
    },

    alignment: {
      type: String,
      default: "center"
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>

<style lang="scss">
  .checkbox-list {
    label {
      &:focus, &:focus-within {
        @apply outline-none;
        @apply shadow-outline;
      }
    }

    &__icon {
      @apply border-gray-800;
      border-color: var(--checkbox-border-color);

      &--checked {
        @apply border-gray-800;
        border-color: var(--checkbox-border-color-active);
        @apply bg-gray-800;
        background-color: var(--checkbox-background-color-active);
      }
    }
  }
</style>
