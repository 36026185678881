<template>
  <div>
    <label
      class="block text-gray-800 text-sm font-bold mb-2"
      v-if="label">
      {{ label }}
    </label>
    <div>
      <div
        v-for="(option,index) in options"
        class="checkbox mb-4"
        :key="index">
        <input
          v-model="model"
          class="clip"
          type="checkbox"
          :id="`checkbox-${uid + index}`"
          :disabled="disabled"
          :value="option[itemKey] ? option[itemKey] : option">
        <label
          :for="`checkbox-${uid + index}`"
          class="flex items-center justify-start select-none cursor-pointer">
          <div class="mr-3">
            <div
              class="flex items-center justify-center rounded w-8 h-8 border bg-white"
              :class="{ 'border-gray-800 bg-gray-800': model.includes(option[itemKey] ? option[itemKey] : option), 'border-gray-400 shadow-inner': !model.includes(option[itemKey ? option[itemKey] : option]) }">
              <svg
                v-if="model"
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 1L4.6 10L1 5.30435"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
          <div
            :class="labelClass"
            class="text-gray-800">
            {{ option[itemLabel] ? option[itemLabel] : option }}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },

    options: {
      type: Array,
      default () {
        return []
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: null
    },

    itemLabel: {
      type: String,
      default: null
    },

    itemKey: {
      type: String,
      default: null
    },

    labelClass: {
      type: [String, Object],
      default: null
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>

<style lang="scss">
  .checkbox {
    input:checked+label {
      .checkbox__icon {
        opacity: 1;
      }
    }

    &:focus, &:focus-within {
      @apply outline-none;
      @apply shadow-outline;
      @apply rounded;
    }
  }
</style>
