<template>
  <button
    :class="classObject"
    :disabled="loading || disabled"
    class="default-button font-semibold focus:outline-none focus:shadow-outline whitespace-no-wrap rounded">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: "Submit"
    },

    primary: {
      type: Boolean,
      default: false
    },

    secondary: {
      type: Boolean,
      default: false
    },

    tertiary: {
      type: Boolean,
      default: false
    },

    orange: {
      type: Boolean,
      default: false
    },

    brandGreen: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    medium: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    },

    skinny: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    classObject () {
      return {
        "loading": this.loading,
        "disabled": this.disabled,
        "text-gray-900 bg-transparent": !this.primary && !this.tertiary && !this.secondary && !this.orange && !this.brandGreen,
        "default-button--primary text-white bg-blue-500 hover:bg-blue-600 border-2 border-transparent": this.primary,
        "text-gray-800 bg-transparent hover:bg-gray-200 border-2 border-gray-300": this.secondary,
        "text-black bg-pink-600 hover:bg-pink-700 border-2 border-transparent text-white": this.tertiary,
        "bg-orange-600 hover:bg-orange-700 border-2 border-transparent text-white": this.orange,
        "bg-brand-green-100 hover:bg-brand-green-130 border-2 border-transparent text-white": this.brandGreen,
        "px-4 py-2 text-xs": this.small,
        "px-6 py-3 text-sm": this.medium,
        "w-full sm:w-auto px-4 sm:px-24 py-4": this.large,
        "px-12 py-3": !this.small && !this.medium && !this.large && !this.skinny,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .default-button {
    &--primary {
      background-color: var(--button-primary-background-color);
      color: var(--button-primary-text-color);

      &:hover {
        background-color: var(--button-primary-background-color-hover);
      }
    }
  }
</style>
