<template>
  <div>
    <datepicker
      v-model="model"
      input-class="w-full bg-white px-4 py-3 rounded leading-snug focus:outline-none focus:shadow-outline text-black border border-gray-400"
      :placeholder="placeholder"
      :monday-first="true"
      :clear-button="true"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"

export default {
  components: {
    Datepicker
  },

  props: {
    value: {
      type: [String, Number, Date],
      default: null
    },

    centered: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: null
    },

    inputmode: {
      type: String,
      default: null
    },

    pattern: {
      type: String,
      default: null
    },

    maxlength: {
      type: String,
      default: null
    },
  },

  mounted () {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>

<style lang="scss">
.vdp-datepicker__clear-button {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
</style>
