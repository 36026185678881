<template>
  <div>
    <figure
      v-show="previewImage"
      class="mb-4 rounded bg-gray-300">
      <img
        :src="previewImage"
        class="object-contain h-48 w-full"
        alt="Forhåndsvisning">
    </figure>
    <label
      v-show="!previewImage"
      :for="uid"
      class="dropzone w-full h-48 rounded cursor-pointer block mx-auto border-2 border-dotted flex items-center justify-center text-blue-800 mb-4">
      <span class="text-sm font-semibold">{{ placeholder }}</span>
      <input
        :id="uid"
        type="file"
        ref="file"
        class="w-full form-control form-input form-input-bordered hidden"
        :accept="accept"
        :disabled="submitting"
        @change="handleChange">
    </label>
    <default-button
      class="w-full mb-4"
      :loading="submitting"
      primary
      small
      @click.prevent.native="openFileField">
      {{ cta }}
    </default-button>
    <div
      v-if="uploadProgress > 0 && submitting"
      class="text-muted text-sm text-center">
      Laster opp... {{ uploadProgress }}%
    </div>

    <error-message v-if="error">
      {{ error }}
    </error-message>
  </div>
</template>

<script>
import Vapor from "laravel-vapor"

export default {
  props: {
    cta: {
      type: String,
      default: "Velg fil"
    },

    placeholder: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      uploadProgress: 0,
      submitting: false,
      path: null,
      error: null,
      previewImage: null
    }
  },

  methods: {
    handleChange() {
      if (this.$refs.file.files.length === 0) return

      this.submitting = true
      this.error = null

      this.triggerPreviewGeneration()

      Vapor.store(this.$refs.file.files[0], {
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100)
        }
      }).then(response => {
        this.submitting = false

        this.$emit("update", {
          uuid: response.uuid,
          key: response.key,
          bucket: response.bucket,
          name: this.$refs.file.files[0].name,
          content_type: this.$refs.file.files[0].type,
        })
      })
      .catch(error => {
        this.error = "Opplastingen feilet"
        throw error
      })
    },

    openFileField () {
      this.$refs.file.click()
    },

    triggerPreviewGeneration () {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.previewImage = e.target.result
        this.$emit("preview", e.target.result)
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    }
  },

  computed: {
    uid() {
      return "upload-" + Math.random().toString(36).substr(2, 9)
    },

    accept () {
      return "image/*"
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropzone {
    background-color: var(--dropzone-background-color);
    color: var(--dropzone-color);
    border-color: var(--dropzone-border-color);

    &:hover {
      background: #fff;
    }
  }
</style>
